
.node-unpublished {
  background-color: #fff4f4;
}
.preview .node {
  background-color: #ffffea;
}
#node-admin-filter ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  width: 100%;
}
#node-admin-buttons {
  float: left; /* LTR */
  margin-left: 0.5em; /* LTR */
  clear: right; /* LTR */
}
td.revision-current {
  background: #ffc;
}
.node-form .form-text {
  display: block;
  width: 95%;
}
.node-form .container-inline .form-text {
  display: inline;
  width: auto;
}
.node-form .standard {
  clear: both;
}
.node-form textarea {
  display: block;
  width: 95%;
}
.node-form .attachments fieldset {
  float: none;
  display: block;
}
.terms-inline {
  display: inline;
}


/*
** HTML elements
*/
fieldset {
  margin-bottom: 1em;
  padding: .5em;
}
form {
  margin: 0;
  padding: 0;
}
hr {
  height: 1px;
  border: 1px solid gray;
}
img {
  border: 0;
}
table {
  border-collapse: collapse;
}
th {
  text-align: left; /* LTR */
  padding-right: 1em; /* LTR */
  border-bottom: 3px solid #ccc;
}

/*
** Markup free clearing
** Details: http://www.positioniseverything.net/easyclearing.html
*/
.clear-block:after {
  content: ".";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}

.clear-block {
  display: inline-block;
}

/* Hides from IE-mac \*/
* html .clear-block {
  height: 1%;
}
.clear-block {
  display: block;
}
/* End hide from IE-mac */


/*
** HTML elements
*/
body.drag {
  cursor: move;
}
th.active img {
  display: inline;
}
tr.even, tr.odd {
  background-color: #eee;
  border-bottom: 1px solid #ccc;
  padding: 0.1em 0.6em;
}
tr.drag {
  background-color: #fffff0;
}
tr.drag-previous {
  background-color: #ffd;
}
td.active {
  background-color: #ddd;
}
td.checkbox, th.checkbox {
  text-align: center;
}
tbody {
  border-top: 1px solid #ccc;
}
tbody th {
  border-bottom: 1px solid #ccc;
}
thead th {
  text-align: left; /* LTR */
  padding-right: 1em; /* LTR */
  border-bottom: 3px solid #ccc;
}

/*
** Other common styles
*/
.breadcrumb {
  padding-bottom: .5em
}
div.indentation {
  width: 20px;
  height: 1.7em;
  margin: -0.4em 0.2em -0.4em -0.4em; /* LTR */
  padding: 0.42em 0 0.42em 0.6em; /* LTR */
  float: left; /* LTR */
}
div.tree-child {
  background: url(../../misc/tree.png) no-repeat 11px center; /* LTR */
}
div.tree-child-last {
  background: url(../../misc/tree-bottom.png) no-repeat 11px center; /* LTR */
}
div.tree-child-horizontal {
  background: url(../../misc/tree.png) no-repeat -11px center;
}
.error {
  color: #e55;
}
div.error {
  border: 1px solid #d77;
}
div.error, tr.error {
  background: #fcc;
  color: #200;
  padding: 2px;
}
.warning {
  color: #e09010;
}
div.warning {
  border: 1px solid #f0c020;
}
div.warning, tr.warning {
  background: #ffd;
  color: #220;
  padding: 2px;
}
.ok {
  color: #008000;
}
div.ok {
  border: 1px solid #00aa00;
}
div.ok, tr.ok {
  background: #dfd;
  color: #020;
  padding: 2px;
}
.item-list .icon {
  color: #555;
  float: right; /* LTR */
  padding-left: 0.25em; /* LTR */
  clear: right; /* LTR */
}
.item-list .title {
  font-weight: bold;
}
.item-list ul {
  margin: 0 0 0.75em 0;
  padding: 0;
}
.item-list ul li {
  margin: 0 0 0.25em 1.5em; /* LTR */
  padding: 0;
  list-style: disc;
}
ol.task-list li.active {
  font-weight: bold;
}
.form-item {
  margin-top: 1em;
  margin-bottom: 1em;
}
tr.odd .form-item, tr.even .form-item {
  margin-top: 0;
  margin-bottom: 0;
  white-space: nowrap;
}
tr.merge-down, tr.merge-down td, tr.merge-down th {
  border-bottom-width: 0 !important;
}
tr.merge-up, tr.merge-up td, tr.merge-up th {
  border-top-width: 0 !important;
}
.form-item input.error, .form-item textarea.error, .form-item select.error {
  border: 2px solid red;
}
.form-item .description {
  font-size: 0.85em;
}
.form-item label {
  display: block;
  font-weight: bold;
}
.form-item label.option {
  display: inline;
  font-weight: normal;
}
.form-checkboxes, .form-radios {
  margin: 1em 0;
}
.form-checkboxes .form-item, .form-radios .form-item {
  margin-top: 0.4em;
  margin-bottom: 0.4em;
}
.marker, .form-required {
  color: #f00;
}
.more-link {
  text-align: right; /* LTR */
}
.more-help-link {
  font-size: 0.85em;
  text-align: right; /* LTR */
}
.nowrap {
  white-space: nowrap;
}
.item-list .pager {
  clear: both;
  text-align: center;
}
.item-list .pager li {
  background-image:none;
  display:inline;
  list-style-type:none;
  padding: 0.5em;
}
.pager-current {
  font-weight:bold;
}
.tips {
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 0;
  padding-bottom: 0;
  font-size: 0.9em;
}
dl.multiselect dd.b, dl.multiselect dd.b .form-item, dl.multiselect dd.b select {
  font-family: inherit;
  font-size: inherit;
  width: 14em;
}
dl.multiselect dd.a, dl.multiselect dd.a .form-item {
  width: 10em;
}
dl.multiselect dt, dl.multiselect dd {
  float: left; /* LTR */
  line-height: 1.75em;
  padding: 0;
  margin: 0 1em 0 0; /* LTR */
}
dl.multiselect .form-item {
  height: 1.75em;
  margin: 0;
}

/*
** Inline items (need to override above)
*/
.container-inline div, .container-inline label {
  display: inline;
}

/*
** Tab navigation
*/
ul.primary {
  border-collapse: collapse;
  padding: 0 0 0 1em; /* LTR */
  white-space: nowrap;
  list-style: none;
  margin: 5px;
  height: auto;
  line-height: normal;
  border-bottom: 1px solid #bbb;
}
ul.primary li {
  display: inline;
}
ul.primary li a {
  background-color: #ddd;
  border-color: #bbb;
  border-width: 1px;
  border-style: solid solid none solid;
  height: auto;
  margin-right: 0.5em; /* LTR */
  padding: 0 1em;
  text-decoration: none;
}
ul.primary li.active a {
  background-color: #fff;
  border: 1px solid #bbb;
  border-bottom: #fff 1px solid;
}
ul.primary li a:hover {
  background-color: #eee;
  border-color: #ccc;
  border-bottom-color: #eee;
}
ul.secondary {
  border-bottom: 1px solid #bbb;
  padding: 0.5em 1em;
  margin: 5px;
}
ul.secondary li {
  display: inline;
  padding: 0 1em;
  border-right: 1px solid #ccc; /* LTR */
}
ul.secondary a {
  padding: 0;
  text-decoration: none;
}
ul.secondary a.active {
  border-bottom: 4px solid #999;
}

/*
** Autocomplete styles
*/
/* Suggestion list */
#autocomplete {
  position: absolute;
  border: 1px solid;
  overflow: hidden;
  z-index: 100;
}
#autocomplete ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
#autocomplete li {
  background: #fff;
  color: #000;
  white-space: pre;
  cursor: default;
}
#autocomplete li.selected {
  background: #0072b9;
  color: #fff;
}
/* Animated throbber */
html.js input.form-autocomplete {
  background-image: url(../../misc/throbber.gif);
  background-repeat: no-repeat;
  background-position: 100% 2px; /* LTR */
}
html.js input.throbbing {
  background-position: 100% -18px; /* LTR */
}

/*
** Collapsing fieldsets
*/
html.js fieldset.collapsed {
  border-bottom-width: 0;
  border-left-width: 0;
  border-right-width: 0;
  margin-bottom: 0;
  height: 1em;
}
html.js fieldset.collapsed * {
  display: none;
}
html.js fieldset.collapsed legend {
  display: block;
}
html.js fieldset.collapsible legend a {
  padding-left: 15px; /* LTR */
  background: url(../../misc/menu-expanded.png) 5px 75% no-repeat; /* LTR */
}
html.js fieldset.collapsed legend a {
  background-image: url(../../misc/menu-collapsed.png); /* LTR */
  background-position: 5px 50%; /* LTR */
}
/* Note: IE-only fix due to '* html' (breaks Konqueror otherwise). */
* html.js fieldset.collapsed legend,
* html.js fieldset.collapsed legend *,
* html.js fieldset.collapsed table * {
  display: inline;
}
/* For Safari 2 to prevent collapsible fieldsets containing tables from dissapearing due to tableheader.js. */
html.js fieldset.collapsible {
  position: relative;
}
html.js fieldset.collapsible legend a {
  display: block;
}
/* Avoid jumping around due to margins collapsing into collapsible fieldset border */
html.js fieldset.collapsible .fieldset-wrapper {
  overflow: auto;
}

/*
** Resizable text areas
*/
.resizable-textarea {
  width: 95%;
}
.resizable-textarea .grippie {
  height: 9px;
  overflow: hidden;
  background: #eee url(../../misc/grippie.png) no-repeat center 2px;
  border: 1px solid #ddd;
  border-top-width: 0;
  cursor: s-resize;
}
html.js .resizable-textarea textarea {
  margin-bottom: 0;
  width: 100%;
  display: block;
}

/*
** Table drag and drop.
*/
.draggable a.tabledrag-handle {
  cursor: move;
  float: left; /* LTR */
  height: 1.7em;
  margin: -0.4em 0 -0.4em -0.5em; /* LTR */
  padding: 0.42em 1.5em 0.42em 0.5em; /* LTR */
  text-decoration: none;
}
a.tabledrag-handle:hover {
  text-decoration: none;
}
a.tabledrag-handle .handle {
  margin-top: 4px;
  height: 13px;
  width: 13px;
  background: url(../../misc/draggable.png) no-repeat 0 0;
}
a.tabledrag-handle-hover .handle {
  background-position: 0 -20px;
}

/*
** Teaser splitter
*/
.joined + .grippie {
  height: 5px;
  background-position: center 1px;
  margin-bottom: -2px;
}
/* Keeps inner content contained in Opera 9. */
.teaser-checkbox {
  padding-top: 1px;
}
div.teaser-button-wrapper {
  float: right; /* LTR */
  padding-right: 5%; /* LTR */
  margin: 0;
}
.teaser-checkbox div.form-item {
  float: right; /* LTR */
  margin: 0 5% 0 0; /* LTR */
  padding: 0;
}
textarea.teaser {
  display: none;
}
html.js .no-js {
  display: none;
}

/*
** Progressbar styles
*/
.progress {
  font-weight: bold;
}
.progress .bar {
  background: #fff url(../../misc/progress.gif);
  border: 1px solid #00375a;
  height: 1.5em;
  margin: 0 0.2em;
}
.progress .filled {
  background: #0072b9;
  height: 1em;
  border-bottom: 0.5em solid #004a73;
  width: 0%;
}
.progress .percentage {
  float: right; /* LTR */
}
.progress-disabled {
  float: left; /* LTR */
}
.ahah-progress {
  float: left; /* LTR */
}
.ahah-progress .throbber {
  width: 15px;
  height: 15px;
  margin: 2px;
  background: transparent url(../../misc/throbber.gif) no-repeat 0px -18px;
  float: left; /* LTR */
}
tr .ahah-progress .throbber {
  margin: 0 2px;
}
.ahah-progress-bar {
  width: 16em;
}

/*
** Formatting for welcome page
*/
#first-time strong {
  display: block;
  padding: 1.5em 0 .5em;
}

/*
** To be used with tableselect.js
*/
tr.selected td {
  background: #ffc;
}

/*
** Floating header for tableheader.js
*/
table.sticky-header {
  margin-top: 0;
  background: #fff;
}

/*
** Installation clean URLs
*/
#clean-url.install {
  display: none;
}

/*
** For anything you want to hide on page load when JS is enabled, so
** that you can use the JS to control visibility and avoid flicker.
*/
html.js .js-hide {
  display: none;
}

/*
** Styles for the system modules page (admin/build/modules)
*/
#system-modules div.incompatible {
  font-weight: bold;
}

/*
** Styles for the system themes page (admin/build/themes)
*/
#system-themes-form div.incompatible {
  font-weight: bold;
}

/*
** Password strength indicator
*/
span.password-strength {
  visibility: hidden;
}
input.password-field {
  margin-right: 10px; /* LTR */
}
div.password-description {
  padding: 0 2px;
  margin: 4px 0 0 0;
  font-size: 0.85em;
  max-width: 500px;
}
div.password-description ul {
  margin-bottom: 0;
}
.password-parent {
  margin: 0 0 0 0;
}
/*
** Password confirmation checker
*/
input.password-confirm {
  margin-right: 10px; /* LTR */
}
.confirm-parent {
  margin: 5px 0 0 0;
}
span.password-confirm {
  visibility: hidden;
}
span.password-confirm span {
  font-weight: normal;
}


ul.menu {
  list-style: none;
  border: none;
  text-align:left; /* LTR */
}
ul.menu li {
  margin: 0 0 0 0.5em; /* LTR */
}
li.expanded {
  list-style-type: circle;
  list-style-image: url(../../misc/menu-expanded.png);
  padding: 0.2em 0.5em 0 0; /* LTR */
  margin: 0;
}
li.collapsed {
  list-style-type: disc;
  list-style-image: url(../../misc/menu-collapsed.png); /* LTR */
  padding: 0.2em 0.5em 0 0; /* LTR */
  margin: 0;
}
li.leaf {
  list-style-type: square;
  list-style-image: url(../../misc/menu-leaf.png);
  padding: 0.2em 0.5em 0 0; /* LTR */
  margin: 0;
}
li a.active {
  color: #000;
}
td.menu-disabled {
  background: #ccc;
}
ul.links {
  margin: 0;
  padding: 0;
}
ul.links.inline {
  display: inline;
}
ul.links li {
  display: inline;
  list-style-type: none;
  padding: 0 0.5em;
}
.block ul {
  margin: 0;
  padding: 0 0 0.25em 1em; /* LTR */
}


#permissions td.module {
  font-weight: bold;
}
#permissions td.permission {
  padding-left: 1.5em; /* LTR */
}
#access-rules .access-type, #access-rules .rule-type {
  margin-right: 1em; /* LTR */
  float: left; /* LTR */
}
#access-rules .access-type .form-item, #access-rules .rule-type .form-item {
  margin-top: 0;
}
#access-rules .mask {
  clear: both;
}
#user-login-form {
  text-align: center;
}
#user-admin-filter ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  width: 100%;
}
#user-admin-buttons {
  float: left; /* LTR */
  margin-left: 0.5em; /* LTR */
  clear: right; /* LTR */
}
#user-admin-settings fieldset .description {
  font-size: 0.85em;
  padding-bottom: .5em;
}

/* Generated by user.module but used by profile.module: */
.profile {
  clear: both;
  margin: 1em 0;
}
.profile .picture {
  float: right; /* LTR */
  margin: 0 1em 1em 0; /* LTR */
}
.profile h3 {
  border-bottom: 1px solid #ccc;
}
.profile dl {
  margin: 0 0 1.5em 0;
}
.profile dt {
  margin: 0 0 0.2em 0;
  font-weight: bold;
}
.profile dd {
  margin: 0 0 1em 0;
}

@charset "utf-8";
* { margin: 0; padding: 0; }

body,html {
	font-weight:normal;
	font-family:Segoe UI, Arial, Helvetica, sans-serif;
	font-size:13px;
	color:#333;
	background:url(images/bk_body.jpg);
	line-height:135%;
}

a {
	text-decoration:none;
	color:#1FA2E1;
}

a:hover {
	text-decoration:underline;
}

a:focus {
	outline:none;
}

p {
	margin:1.5em 0;
}

h1 {
	font-size:30px;
	font-weight:normal;
	line-height: 100%;
}

h1 a{
	color:#1F88A7;
}

h1 a:hover {
	text-decoration:none;
	color:#333;
}

h2{
	font-size:20px;
	line-height: 100%;
}

h3{
	font-size:16px;
	line-height: 100%;
}

code { font-family:"Courier New", Courier, monospace; }

blockquote {
	border-left:1px dashed #AAAAAA;
	color:#555;
	font-family:Georgia,"Times New Roman",Times,serif;
	font-style:italic;
	line-height:18px;
	margin:15px 0 15px 20px;
	padding-left:20px;
}

ul {
    /*list-style: none outside none;*/
}

 /* LAYOUT STRUCTURE
--------------------------------------------------*/
#mainWrapper {
	background:url(images/bk_main_wrapper.png) 0 0 repeat-x;
}

#wrapper {
	margin:0 auto;
	width:960px;
}

#header {
	height:200px;
	position:relative;
}

#content {
	padding-bottom:20px;
	overflow:hidden;
	margin-top:25px;
 	text-shadow:#eee 1px 1px;
	min-height:550px;
}

#content #colLeft {
	float:left;
	width:654px;
	margin-right:30px;
	padding-top:10px;
}

#content #colRight {
	float:left;
	width:265px;
}

#footer {
	background:#111;
	padding:15px 0 30px;
	margin-top:20px;
	text-shadow:1px 1px #000;
	border-top:3px solid #000;
}

#footerInner {
	width:960px;
	margin:0 auto;
	padding:20px 0 35px;
	overflow:hidden;
}


/* HEADER ELEMENTS
-----------------------------------*/

/* -- logo --*/
#logo {
	position:absolute;
	top:90px;
	left:0;
}

#logo a img{
	border:none;
	vertical-align:middle;
}

#topMenu {
	position:absolute;
	left:-13px;
	top:0;
	text-shadow:1px 1px #000;
}

#topMenu ul.primary-links li {
	display: block;
	float: left;
	color:#ccc;
	text-transform:none;
	padding:0;
}


#topMenu ul.primary-links li a {
	float:left;
	display:block;
	height:32px;
	color:#3FAEA5;
	padding:18px 15px 0;
	text-transform:uppercase;
	font-size:12px;
}


#topMenu ul.primary-links li a:hover, #topMenu ul.primary-links li a.selected, #topMenu ul.primary-links li a.active  {
	text-decoration:none;
	background:url(images/bk_topmenu_hover.jpg) 0 0 repeat-x;
	color:#FFF;
	text-shadow:1px 1px #666;
}

/* top social links */

#topSocial {
	position:absolute;
	right:0;
	top:100px;
}

#topSocial ul { list-style: none outside none; padding:0; margin:0;}

#topSocial ul li {
	float:left;
	padding:0 5px;
	width:50px;
}

#topSocial ul li a {
	display:block;
	height:49px;
	width:49px;
	background:url(images/bk_topsocial.png) 0 0 no-repeat;
	text-indent:-9999px;
}

#topSocial ul li a.twitter {
	background:url(images/ico_social_twitter.png) 0 0 no-repeat;
}	

#topSocial ul li a.facebook {
	background:url(images/ico_social_facebook.png) 0 0 no-repeat;
}

#topSocial ul li a.rss {
	background:url(images/ico_social_rss.png) 0 0 no-repeat;
}


#topSocial ul li a.twitter:hover, #topSocial ul li a.facebook:hover, #topSocial ul li a.rss:hover {
	background-position:0 -49px;
}

/* Top Search */

#topSearch {
	position:absolute;
	right:0;
	top:13px;
}

#topSearch input[type="text"] {
    -moz-border-radius: 6px 6px 6px 6px;
    border: 1px solid #FFFFFF;
    font-style: italic;
	margin:0 -20px 0 0;
    padding: 4px 10px;
    position: relative;
    width: 160px;
}

#topSearch label { color:#ffffff; font-weight:normal;}

#topSearch input[type="submit"] {
    background: url("images/ico_search.png") no-repeat scroll 98% 50% #FFFFFF;
    margin:0 12px 0 0;
    padding: 0 2px 0 0;
    position: relative;
    width: 14px;
	border:none;
	text-indent: -9999px;
	cursor:pointer;
}

/* CONTENT ELEMENTS
-----------------------------------*/

pre {
	font-size: 1.3 em; 
	padding:10px; 
	background: #E3E8EA;
	display:block;
	border:1px solid #FDFDFD;
	margin-bottom:25px;
	clear:both;
}

#content #colLeft ul li, #content #colLeft ol li  {
	/*padding:5px 0 5px 20px;
	background:url(images/bullet_list.png) 0 8px no-repeat;*/
}

#content #colLeft ol li {
	background:none;
	padding-left:0;

}

#content #colLeft ul, #content #colLeft ol {
	margin:0;
}

/* POST BOXES */

#content .node-front {
	float:left;
	/* 	margin:0 42px 30px 0; */
	margin:0 15px 30px 15px;
	width:290px;
	background:url(images/box_botttom_shadow.png) 50% 100% no-repeat;
	padding-bottom:5px;
	position:relative;
}


#content .lastBox {
	margin-right:0;
}

#content .nodeInner {
	border:8px solid #F7F9F9;
	background:#F7F9F9;
	padding:10px;
	-moz-border-radius:8px;
    -webkit-border-radius:8px;
	/* 	height:260px; */
	height:320px;
	font-size:12px;
	overflow:hidden;
}

#content .nodeInner div.excerpt {
	height:80px;
}

#content .nodeInner:hover {
	background:#FFF;
	border:8px solid #EEF2F2;
}

#content .nodeInner img {
	border:none;
}

#content .nodeInner p {
	margin:0 0 1.5em;
}

#content .nodeInner div.meta{
	font-size:11px;
	color:#888;
	margin:0;
	text-transform:uppercase;
	position:absolute;
	left:18px;
	bottom:25px;
}

#content .nodeInner div.meta img {
	vertical-align:middle;
}

#content .node-front .nodeInner h2 {
	font-size:20px;
	font-weight:normal;
	line-height: 110%;
    margin-bottom: 0;
    padding: 10px 0;
}

#content #colLeft .node-front .nodeInner h2 {
	margin-bottom:0;
}

#content .nodeInner h2 a {
	color:#353535;
}

#content .nodeInner h2 a:hover {
	color:#1FA2E1;
	text-decoration:none;
}

#content .sticky .nodeInner h2 a:hover {
	color:#399b8c;
	text-decoration:none;
}

#content .node-front .node_read_more a{
	position:absolute;
	bottom:-5px;
	right:20px;
	display:block;
	width:46px;
	height:46px;
	text-indent:-9999px;
	background:url(images/bk_readmore.png) 0 0 no-repeat;
}

#content .node-front .nodeInner ul.links li.upload_attachments {
	display:block;
}

#content .node-front.sticky .nodeInner ul.links li.upload_attachments {
	display:inline-block;
}

#content .node-front .node_read_more:hover a{
	background:url(images/bk_readmore.png) 0 -46px no-repeat;
}

#content img {
    -moz-border-radius: 8px 8px 8px 8px;
    background: none repeat scroll 0 0 #EEF2F2;
    border: medium none;
    /*padding: 10px;*/
}

#content img.node-front-image  {
	display:none;
}

#content .node-front img.node-front-image  {
	display:block;
}

#content .node-front.sticky  {
    float: left;
    position: relative;
    width: 450px;
	margin: 0;
    padding: 0 15px 30px 15px;
	border:none;
	background:none;
    background: url("images/bk_dotted_big.png") repeat-x scroll 0 100% transparent;
    margin-bottom: 30px;
}

#content .node-front.sticky.node-taxonomy  {
    margin-left:87px;
}

#content .node-front.sticky .nodeInner {
	background: no-repeat scroll 365px 0 transparent;
	border:none;
	padding:0;
	overflow: hidden;
	display:block;
	height:500px;
}

#content .node-front.sticky .nodeInner h2, #content #colLeft .node-front.sticky.node-taxonomy .nodeInner h2 { 
    font-weight: normal;
    line-height: 100%;
    margin-bottom: 25px;
    padding-right: 80px;
    padding-top: 5px;
	font-size:240%;
}

#content .node-front.sticky .node_read_more a{
    background: url("images/bk_readmore.png") no-repeat scroll 0 -46px transparent;
    display: block;
    height: 46px;
    left: 370px;
    position: absolute;
    text-indent: -9999px;
	top: 365px;
    width: 46px;
}

#content .node-front.sticky .node_read_more a:hover {
    background-position:0 0;
}

ul.links li { padding:0 4px; }

ul.links li.comment_comments, ul.links li.comment_add, ul.links li.comment_forbidden { background: transparent url("images/ico_post_comments.png") no-repeat left center; margin:0 0 0 5px; padding:0 0 0 15px;}

#content #colLeft h1, #content #colLeft h2 {
    margin-bottom: 20px;
}

#content .meta {
    background: url("images/bk_dotted_big.png") repeat-x scroll 0 100% transparent;
    color: #666666;
    font-size: 11px;
    /*margin: 0  0 15px;*/
    padding-bottom: 18px;
    text-transform: uppercase;
}

#content #colLeft div.submitted {
margin:0;
padding:0;
float:left;
}

#content #colLeft div.terms {
background: url("images/ico_tag.png") no-repeat scroll 0 50% transparent;
margin:0 0 0 20px;
padding:0 0 0 20px;
float:left;
}


#content #colLeft div.meta ul li {
    background: none repeat scroll 0 0 transparent;
}

/* COL RIGHT
--------------------------------*/

#colRight .block  {
	background:url(images/box_botttom_shadow_right.png) 50% 100% no-repeat;
	padding-bottom:4px;
	margin-bottom:12px;
}

#colRight .blockInner {

	background:#F7F9F9;
	padding:10px;
	padding-bottom:20px;
	-moz-border-radius:8px;
    -webkit-border-radius:8px;
	border:8px solid #EEF2F2;
}

#colRight .blockInner h2 {
	padding:10px 0;
	text-transform:uppercase;
	font-size:15px;
	border-bottom:1px solid #ddd;
}

#colRight .blockInner ul.menu {
	padding:0;
}

#colRight .blockInner ul.menu li {
	margin:0;
	list-style:none;
}

#colRight .blockInner ul li{
	padding:6px 0;
	border-bottom:1px solid #ddd;
}

#colRight .blockInner ul li, #colRight .blockInner p, #colRight .blockInner div {
	text-shadow:none;
}

#colRight .blockInner p {
	margin:0.7em 0;
}

/* PAGINATION */

ul.pager {overflow:hidden;text-align:center; /*background:url(images/bk_pagination.png) 0 50% repeat-x;*/ margin:15px auto 0; font-size:18px;  padding-right:2px; }

ul.pager li.pager-current { background:url(images/bk_pagination_item.png); margin:0 5px;display:block; overflow:hidden; width:41px; height:31px; text-align:center; text-decoration:none; float:left; font-weight:bold; padding:10px 0 0 0; color:#000; }
ul.pager li a { background:url(images/bk_pagination_item.png);color:#26B8A3; margin:0 5px;display:block;overflow:hidden;  width:41px; height:31px; text-align:center; text-decoration:none; float:left;padding-top:10px; font-weight:bold; }
ul.pager li a:hover { color:#000; }
ul.pager li.pager-next a, ul.pager li.pager-last a, ul.pager li.pager-first a,ul.pager li.pager-previous a{ background:none; width:61px; }
ul.pager li.pager-previous a{ width:91px; }
.item-list .pager li { padding: 0; }
.item-list ul li { margin:0; }

/* FOOTER ELEMENTS
--------------------------------*/
/* Footer Widgets */

#footerInner .blockFooter {
	float:left;
	width:210px;
	padding-right:30px;
	overflow:hidden;
	margin-bottom:30px;
	color:#ccc;
}


#footerInner h2 {
	font-size:20px;
	border-bottom:1px solid #292929;
	padding:7px 0 12px;
	margin-bottom:2px;
	color:#ccc;
	font-weight:normal;
	text-shadow:none;
}

#footerInner .blockFooter ul.menu {
	padding:0;
}

#footerInner .blockFooter ul li, #footerInner .blockFooter ul.menu li {
	padding:7px 0;
	margin:0;
	border-bottom:1px dotted #292929;
	list-style: none outside none;
}

#footerInner .blockFooter ul li a {
	text-decoration:none;
	color:#555;
	font-size:13px;
	font-style:normal;
}

#footerInner .blockFooter ul li a:hover {
	color:#ccc;
}

#footer-message { clear:both; color:#CCCCCC; }


/* Comments */

.comment, .comment-level1, .comment-level2 {
	background: url("images/bk_dotted_big.png") repeat-x scroll 0 100% transparent;
    margin-top: 25px;
    padding: 0 0 20px 0;
    position: relative;
}

div.comment h3 { padding: 5px 0 0 0; }

#content #colLeft div.comment  ul { margin:0; }

#content #colLeft div.comment  ul li { background:none; font-size:12px; font-weight:bold; text-transform:uppercase; padding:0 5px 0 0; }

h2.comments {
    background: url("images/ico_comments.png") no-repeat scroll 0 50% transparent;
    display: block;
    margin: 20px 0 0;
    padding: 10px 0 10px 40px;
}


/* COMMENTS F0RM */

#comment-form p {
	margin:0;
	padding:0;
}

#comment-form label {
	display:block;
	padding:5px 0;
}

#comment-form input.form-text, #comment-form textarea {
	border:1px solid #fff;
	border-top:1px solid #B8C4C5;
	border-left:1px solid #B8C4C5;
	padding:8px;
	margin-bottom:10px;
   -moz-border-radius:4px;
   -webkit-border-radius:4px;
    width:350px;
}

#comment-form input.form-autocomplete { padding:3px 4px; }

#comment-form input.form-text:focus, #comment-form textarea:focus {
	border:1px solid #45C0B6;
}


#comment-form textarea {
	font-family:Segoe UI, Arial, Helvetica, sans-serif;
	font-size:13px;
	width:630px;
}

#comment-form input#submit{
	border:none;
	width:161px;
	height:42px;
	margin-top:10px;
	cursor:pointer;
	background:url(images/but_form.png) 0 0 no-repeat;
	color:#FFF;
	font-size:12px;
	padding-bottom:14px;
	text-shadow:1px 1px #23829F;
}


/* FORMS
--------------------------------*/

/* Top Search */

#topSearch input[type="text"] {
	-moz-border-radius:4px; 
	-webkit-border-radius:4px;
	width:160px;
	background:#fff url(images/ico_search.png) 98% 50% no-repeat;
	padding:4px 10px 4px;
	font-style:italic;
	margin-right:10px;
	position:relative;
	-moz-border-radius:6px;
    -webkit-border-radius:6px;
	border:1px solid #fff;
}

#topSearch input[type="submit"] {
	position:absolute;
	right:0;
	z-index:100;
	width:43px;
	height:44px;
	background:url(images/but_search.png) 0 0 no-repeat;
	border:none;
	cursor:pointer;
}	

/* Contact Form */

#contact { padding:20px 0 0 0; } 

#contact label {
	display:block;
	padding:0 0 5px 0;
}

#contact input, #contact textarea {
	border:1px solid #fff;
	border-top:1px solid #B8C4C5;
	border-left:1px solid #B8C4C5;
	padding:8px;
	margin-bottom:10px;
   -moz-border-radius:4px;
   -webkit-border-radius:4px;
   	width:350px;
}


#contact textarea {
	width:560px;
	font-family:Arial, Helvetica, sans-serif;
	font-size:13px;
}

#contact input[type="submit"]{
	border:none;
	width:161px;
	height:42px;
	margin-top:10px;
	cursor:pointer;
	background:url(images/but_form.png) 0 100% repeat-x;
	color:#FFF;
	font-size:12px;
	padding-bottom:14px;
	text-shadow:1px 1px #23829F;
}


table {width:100%; border-spacing:0; border:1px solid #dedfe1; border-collapse:collapse; text-align:left;}
table th { background:#E3E8EA; border-bottom: 1px solid #CCCCCC; }
table tr.odd { background:#F7F9F9; }
table tr.even { background:#f3f3f3; }
table td , table th{ padding:5px; }

/*  --------------------------------------------------------------------- */
table { margin:15px 0; border: 1px solid #cdcdcd; border-collapse:collapse; border-spacing:0; font-size:100%; width: 100%;}
th { text-align:center; font-weight:bold; border: 1px solid #cdcdcd;}
th, td { padding:4px 6px; border: 1px solid #cdcdcd;}
tr.table-top {background: #e5e5e5; font-weight: bold;}

/*  --------------------------------------------------------------------- */
ul, ol { display:block; margin:15px 0 15px 20px; }
ul ul, ul ul ul, ol ol, ol ol ol ,
#content #colLeft ul, #content #colLeft ol, #content #colLeft ul ul, #content #colLeft ul ul ul, #content #colLeft ol ol, #content #colLeft ol ol ol { margin:0; margin-left:20px; }
ol { list-style-type:decimal; }
ol ol { list-style-type:upper-alpha; }
ol ol ol {list-style-type:lower-alpha; }
li { display:list-item; list-style-position: inside; }

#autocomplete ul,#autocomplete ul li:hover { background:#ffffff; margin:0; padding:0; color:#1FA2E1; cursor:pointer;}

#content #colLeft #autocomplete ul  { margin:0;} 

#content #colLeft #autocomplete ul li { background:none; padding:0;} 

div.messages.status {
	background:#44c0b6;
	border:1px solid #333;
	padding:10px;
	margin:0 0 15px 0;
	color:#ffffff;
	text-shadow:none;
}

div.messages.status a, div.messages.error a, div.messages.warning a { color:#ffffff; text-decoration:underline; }

div.messages.error {
	background:#d93628;
	border:1px solid #333;
	padding:10px;
	margin:0 0 15px 0;
	color:#ffffff;
	text-shadow:none;
}

div.messages.warning {
	background:#de7800;
	border:1px solid #333;
	padding:10px;
	margin:0 0 15px 0;
	color:#ffffff;
	text-shadow:none;
}

a.smashing:link, a.smashing:active, a.smashing:visited, a.smashing:hover, a.drupalizing:link, a.drupalizing:active, a.drupalizing:visited, a.drupalizing:hover {
    background: url("images/footer-logo-smashing.png") no-repeat scroll center top transparent;
    display: block;
    float: left;
    height: 54px;
    margin: 0;
    outline: 0 none;
    padding: 0;
    text-indent: -9999px;
    width: 154px;
}

a.drupalizing:link, a.drupalizing:active, a.drupalizing:visited, a.drupalizing:hover {
    background: url("images/footer-logo-drupalizing.png") no-repeat scroll center top transparent;
}